import React, { useState } from 'react'
import PropTypes from 'prop-types'
import uuid from 'uuid'

const FilterElement = ({ isSelected, toggleFilter, level, label }) => {
  const liClass = () => {
    switch (level) {
      case 1:
        return 'first-depth'
        break
      case 2:
        return 'second-depth'
        break
      default:
        return ''
    }
  }

  return(
    <li
      className={liClass()}
      onClick={toggleFilter}
    >
      <div className={`checkbox ${isSelected ? 'checked' : ''}`} />
      <label>{label}</label>
    </li>
  )
}

const checkboxClass = (slug, selectedFilters, optionId) => {
  const checked = selectedFilters[slug].find(filter => filter.id === optionId)

  return `checkbox ${checked ? 'checked' : ''}`
}

const Filter = ({ filter, open, setOpenFilter, selectedFilters, toggleFilter, isSelected }) => {
  const [inputValue, setInputValue] = useState('')
  const [selected, setSelected] = useState(isSelected)
  const { options, name, slug } = filter

  const onClose = () => {
    setOpenFilter(null)
    setInputValue('')
  }

  const toggleOpen = () => {
    if (open) {
      onClose()
    } else {
      setOpenFilter(slug)
    }
  }

  return(
    <div className="filter">
      <div onClick={toggleOpen} className={`filter-btn ${open ? 'open' : ''}`}>{name}</div>
      { open && (
        <>
          <div className="close-glass" onClick={onClose} />
          <div className="filter-popup">
            <div className="title">{name}</div>
            <div onClick={onClose} className="close" />
            <input className="autocomplete" onChange={(e) => setInputValue(e.target.value)} type="text" />

            <ul className="list">
              {options.filter(option => option.name.toLowerCase().includes(inputValue.toLowerCase())).map((option) => (
                <FilterElement
                  key={option.id}
                  toggleFilter={() => toggleFilter(slug, option)}
                  isSelected={!!selectedFilters[slug].find(filter => filter.id === option.id)}
                  level={option.level}
                  label={option.name}
                />
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  )
}

export default Filter
