import React, { useState } from 'react'
import PropTypes from 'prop-types'

const SelectedFilter = ({ filter, groupSlug, selectedFilters, setSelectedFilters }) => {
  const removeFilter = () => {
    const newFilterList = {}
    newFilterList[groupSlug] = [ ...selectedFilters[groupSlug].filter(option => option.id !== filter.id)]

    setSelectedFilters({ ...selectedFilters, ...newFilterList })
  }

  return(
    <div className="selected-filter">
      <div className="name">{filter.name}</div>
      <div className="remove" onClick={() => removeFilter()} />
    </div>
  )
}

export default SelectedFilter
