import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Card from './card'
import Filter from './filter'
import SelectedFilter from './selected_filter'
import uuid from 'uuid'


const DESC = 'desc'
const ASC = 'asc'

const intercept = (arrays, resultArr, index) => {
  if ((arrays.length - 1) < index) { return resultArr }

  return intercept(arrays, resultArr.filter(slug => arrays[index].includes(slug)), index + 1)
}

const buildFilteredCards = (cards, selectedFilters, tags) => {
  let result = []

  if (Object.values(selectedFilters).flat().length === 0) { return cards }

  Object.keys(selectedFilters).forEach(key => {
    if (selectedFilters[key].length > 0) {
      let group = []

      selectedFilters[key].map(option => group = group.concat(tags[option.id]))
      result.push(group)
    }
  })

  if (result.lenght === 1) {
    result = result.flat()
  } else {
    result = intercept(result, result[0], 1)
  }

  return cards.filter(card => result.includes(card.slug))
}

const buildSelectedFiltersStructure = (filters) => {
  let result = {}

  filters.forEach(filter => result[filter.slug] = [])

  return result
}

const LeagueTable = ({ cards, tags, filters }) => {
  const [openFilter, setOpenFilter] = useState(null)
  const [selectedFilters, setSelectedFilters] = useState(buildSelectedFiltersStructure(filters))
  const filteredCards = buildFilteredCards(cards, selectedFilters, tags)

  const toggleFilter = (cardSlug, clickedFilter) => {
    const { id } = clickedFilter
    const newFilterList = {}
    const selectedFilter = selectedFilters[cardSlug].find(filter => filter.id === id)

    if (!selectedFilter) {
      newFilterList[cardSlug] = [ ...selectedFilters[cardSlug], clickedFilter]
    } else {
      newFilterList[cardSlug] = [ ...selectedFilters[cardSlug].filter(filter => filter.id !== id) ]
    }

    setSelectedFilters({ ...selectedFilters, ...newFilterList })
  }

  const renderFilters = () => (
    <div className="filters-container">
      <div className="filters">
        <div key={uuid()} className="main-label">filter awards</div>
        { filters.map(filter => (
            <Filter
              key={filter.id}
              filter={filter}
              open={openFilter == filter.slug}
              setOpenFilter={setOpenFilter}
              toggleFilter={toggleFilter}
              selectedFilters={selectedFilters}
            />
          ))
        }
      </div>
    </div>
  )

  const renderSelectedFilters = () => (
    <div className="selected-filters-container">
      <div className="main-label">{`Total (${cards.length})`}</div>
      <div className="filters">
        {
          Object.keys(selectedFilters).map(key => (
            selectedFilters[key].map(filter => (
              <SelectedFilter
                key={uuid()}
                filter={filter}
                groupSlug={key}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
              />
            ))
          )).flat()
        }
      </div>
    </div>
  )

  const renderCards = () => (
    filteredCards.map(card => <Card
      key={card.slug}
      slug={card.slug}
      period={card.period}
      subject={card.subject}
      tags={card.tags}
      date={card.date}
    />)
  )

  const renderEmptyView = () => (
    <div className="awards-empty-view-container">
      <div className="seal" />
      <div className="description">Your search returned no results. Try removing your last added filter or rephrasing your search.</div>
    </div>
  )


  return(
    <>
      {renderFilters()}
      {renderSelectedFilters()}
      <div className="grid-container awards">
        {filteredCards.length > 0 ? renderCards() : renderEmptyView()}
      </div>
    </>
  )
}

LeagueTable.defaultProps = {
  cards: [],
}

LeagueTable.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.shape({})),
}

export default LeagueTable
