import React from 'react'
import uuid from 'uuid'
import PropTypes from 'prop-types'

const EXCLUDED_TYPE = 'AwardTag::Year'

const Card = ({ slug, date, period, subject, tags, cardType }) => {
  return (
    <article className="card-container">
      <div className="background-icon" />
      <a href={`/${cardType}/${slug}`}>
        <div className="header-wrapper">
          <div className="header">
            <time className="date" dateTime={`${date}`} title="Award Card date">{period}</time>
            <div className="ribbon" />
          </div>
          <div className="separator gold" />
        </div>
        <div className="content">
          <div className="text">{subject}</div>
        </div>
        <div className="tags-container">
          {tags.filter(tag => tag.type !== EXCLUDED_TYPE).map(tag => <div className="league-tag" key={uuid()}>{tag.name}</div>)}
        </div>
      </a>
    </article>
  )
}

Card.defaultProps = {
  date: '',
  tags: [],
}

Card.propTypes = {
  slug: PropTypes.string.isRequired,
  period: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  cardType: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.shape({})),
  date: PropTypes.string,
}

export default Card
